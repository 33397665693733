import React from "react";
import { useAuth } from "../context/useAuth";

type Props = { children: React.ReactNode };

const ProtectedRoute = ({ children }: Props) => {
  const { isAdmin } = useAuth();
  return isAdmin() ? <>{children}</> : <></>;
};

export default ProtectedRoute;
