import { handleError } from "../helpers/ErrorHandler";
import { NotificationEmail } from "../models/NotificationEmails";
import axiosInstance from "./AxiosInstance";
import axios from "axios";

interface NotificationEmailResponse {
  results: NotificationEmail[];
}

interface GenericResponse {
  result: string;
}

export const getNotificationEmails = async () => {
  try {
    const data = await axiosInstance.get<NotificationEmailResponse>(
      "/api/notification-emails"
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const addNotificationEmail = async (email: string) => {
  try {
    const data = await axiosInstance.post<GenericResponse>(
      `/api/notification-emails`,
      {
        email: email,
      }
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteNotificationEmail = async (notificationEmailId: string) => {
  try {
    const data = await axiosInstance.delete<GenericResponse>(
      `/api/notification-emails/${notificationEmailId}`
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
