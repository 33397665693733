import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectGroupRoles from "./SelectGroup/SelectGroupRoles";
import { registerAPI } from "../../services/AuthService";

type Props = {};

const AddUserForm = (props: Props) => {
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (e.target.form[2].value.localeCompare(e.target.form[3].value)) {
      toast.warning("podane hasła się nie zgadzają");
    } else {
      await registerAPI(
        e.target.form[0].value,
        e.target.form[1].value,
        e.target.form[2].value,
        [e.target.form[4].value]
      ).then((res) => {
        if (res) {
          toast.success("Użytkownik został dodany pomyślnie");
          navigate("/users");
        }
      });
    }
  };
  return (
    <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
      <div className="flex flex-col gap-9">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
            <h3 className="font-medium text-black dark:text-white">
              Dodaj użytkownika
            </h3>
          </div>
          <form>
            <div className="p-6.5">
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full xl:w-1/2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    email<span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    required
                  />
                </div>

                <div className="w-full xl:w-1/2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    login<span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="login"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    required
                  />
                </div>
              </div>

              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  hasło<span className="text-meta-1">*</span>
                </label>
                <input
                  type="password"
                  placeholder="hasło"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  required
                />
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  powtórz hasło<span className="text-meta-1">*</span>
                </label>
                <input
                  type="password"
                  placeholder="hasło"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  required
                />
              </div>
              <SelectGroupRoles />
              <button
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                type="submit"
                onClick={handleSubmit}
              >
                Dodaj Użytkownika
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
