import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { editVms, getVms } from "../../services/ParkingService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Vms } from "../../models/Vms";
import SelectGroupParkings from "./SelectGroup/SelectGroupParkings";
import Spinner from "../Spinner/Spinner";

type Props = {};

const EditVmsForm = (props: Props) => {
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await editVms(
      vmsId ? vmsId.toString() : "",
      e.target.form[0].value,
      e.target.form[1].value,
      e.target.form[2].value,
      e.target.form[3].value,
      e.target.form[4].value,
      e.target.form[5].value
    ).then((res) => {
      if (res) {
        toast.success("VMS został zaktualizowany pomyślnie");
        navigate(`/vmses/${vmsId}`);
      }
    });
  };
  let { vmsId } = useParams();
  const [vms, setVms] = useState<Vms>();
  const [apiError, setApiError] = useState<string | null>(null);
  useEffect(() => {
    const getVmsInit = async () => {
      const result = await getVms(vmsId!);
      if (typeof result === "string") {
        setApiError(result);
      } else if (result.data.vms) {
        setVms(result.data.vms);
      }
    };
    getVmsInit();
  }, []);
  return (
    <>
      {vms && !apiError ? (
        <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
          <div className="flex flex-col gap-9">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Edytuj VMS
                </h3>
              </div>
              <form>
                <div className="p-6.5">
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        ID
                      </label>
                      <input
                        type="number"
                        placeholder="ID Znaku"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        required
                        defaultValue={vms.id}
                        disabled={true}
                      />
                    </div>

                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        adres IP
                      </label>
                      <input
                        type="text"
                        placeholder="10.8.0.XXX"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        required
                        defaultValue={vms.ip_address}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Adres
                    </label>
                    <input
                      type="text"
                      placeholder="Adres znaku"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                      defaultValue={vms.address}
                    />
                  </div>
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Latitude
                      </label>
                      <input
                        type="number"
                        placeholder="szerokość geograficzna"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        defaultValue={vms.latitude}
                      />
                    </div>
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Longitude
                      </label>
                      <input
                        type="number"
                        placeholder="długość geograficzna"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        defaultValue={vms.longitude}
                      />
                    </div>
                  </div>
                  <SelectGroupParkings selectedParkingId={vms.parking.id} />
                  <button
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Aktualizuj VMS
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : apiError ? (
        <>
          ZNAK <b>{vmsId}</b> nie istnieje
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default EditVmsForm;
