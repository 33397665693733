import ReactApexChart from "react-apexcharts";
import Spinner from "../Spinner/Spinner";
import { VmsVoltageHistoryReport } from "../../models/Vms";
import apexVoltageOptions from "./ChartVoltageOptions";

type Props = {
  report: VmsVoltageHistoryReport[];
};

const VmsVoltageHistoryChart = ({ report }: Props) => {
  const dataSet = report?.map((entry) => {
    return {
      x: new Date(entry.timestamp)!,
      y: entry.voltage_avg,
    };
  });

  const input = dataSet ? dataSet : [{ x: 0, y: 0 }];
  const data = [
    {
      name: "napięcie",
      data: input,
    },
  ];

  return (
    <>
      {report ? (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
          <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
            <div className="flex w-full flex-wrap gap-3 sm:gap-5"></div>
            <div className="flex w-full max-w-45 justify-end"></div>
          </div>

          <div>
            <div id="vmsVoltageHistoryChart" className="-ml-5">
              <ReactApexChart
                options={apexVoltageOptions}
                series={data}
                type="line"
                height={350}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default VmsVoltageHistoryChart;
