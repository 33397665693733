import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = "https://api.warszawa.prestigesystems.pl";
// const baseUrl = "http://localhost:9000";

let axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("accessToken");
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    if (error.response.status === 403) {
      toast.error("Brak autoryzacji");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
