import React, { useEffect, useState } from "react";
import { User } from "../../models/User";
import { getUsers } from "../../services/UserService";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";

type Props = {};

const UsersTable = (props: Props) => {
  const [users, setUsers] = useState<User[]>();
  useEffect(() => {
    const getUsersInit = async () => {
      const result = await getUsers();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setUsers(result.data.results);
      }
    };
    getUsersInit();
  }, []);

  return (
    <>
      {users ? (
        <>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      Użytkownik
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      Role
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Data utworzenia
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {user.username}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {user.email}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <span className="text-black dark:text-white">
                          {user.roles.map((role, key) => role.name)}
                        </span>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {moment(user.time_created).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <Link
                            className="hover:text-primary"
                            to={`${user.id}/edit`}
                          >
                            Edytuj
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center px-4 py-3">
                <div className="text-sm text-slate-500">
                  Liczba użytkowników: <b>{users.length}</b>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default UsersTable;
