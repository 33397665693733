import axios from "axios";
import { toast } from "react-toastify";

export const handleAuthError = (error: any) => {
  if (axios.isAxiosError(error)) {
    var err = error.response;
    if (Array.isArray(err?.data.errors)) {
      for (let val of err?.data.errors) {
        toast.warning(val.description);
      }
    } else if (typeof err?.data.errors === "object") {
      for (let e in err?.data.errors) {
        toast.warning(err.data.errors[e][0]);
      }
    } else if (err?.data) {
      if (Array.isArray(err.data._schema)) {
        err.data._schema.forEach(function (text: any) {
          toast.warning(text);
        });
      } else if (Array.isArray(err.data.new_password)) {
        err.data.new_password.forEach(function (text: any) {
          toast.warning(text);
        });
      }
      {
        if (typeof err?.data.errors === "object") {
        } else {
          toast.warning(err.data.result);
        }
      }
    } else if (err?.status === 401) {
      toast.warning("Please login");
      window.history.pushState({}, "LoginPage", "/login");
    } else if (err) {
      toast.warning(err?.data);
    }
  }
};
