import { handleAuthError } from "../helpers/AuthErrorHandler";
import { Role, User } from "../models/User";
import axiosInstance from "./AxiosInstance";
import axios from "axios";

interface UsersResponse {
  results: User[];
}

interface UserResponse {
  result: User;
}

interface RolesResponse {
  results: Role[];
}

interface GenericResponse {
  result: string;
}

export const getUsers = async () => {
  try {
    const data = await axiosInstance.get<UsersResponse>("/api/users");
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getUser = async (userId: string) => {
  try {
    const data = await axiosInstance.get<UserResponse>(`/api/users/${userId}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getRoles = async () => {
  try {
    const data = await axiosInstance.get<RolesResponse>("/api/roles");
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const editUser = async (userId: string, password: string) => {
  try {
    const data = await axiosInstance.put<RolesResponse>(
      `/api/users/${userId}`,
      {
        password: password,
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const deleteUser = async (userId: string) => {
  try {
    const data = await axiosInstance.delete<GenericResponse>(
      `/api/users/${userId}`
    );
    return data;
  } catch (error) {
    handleAuthError(error);
  }
};

export const whoAmI = async () => {
  try {
    const data = await axiosInstance.get<UserResponse>(
      "/api/self-service/my-profile"
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const updatePassword = async (
  old_password: string,
  new_password: string
) => {
  try {
    const data = await axiosInstance.put<GenericResponse>(
      "/api/self-service/change-password",
      {
        old_password: old_password,
        new_password: new_password,
      }
    );
    return data;
  } catch (error) {
    handleAuthError(error);
  }
};
