import React, { useEffect, useState } from "react";
import {
  getParkingAverageOccupancyReport,
  getParkingList,
} from "../../services/ParkingService";
import { Parking, ParkingAverageOccupancyReport } from "../../models/Parking";
import DatePickerRange from "../Forms/DatePicker/DatePickerRange";
import moment from "moment";
import ParkingUsageChart from "../Charts/ParkingUsageChart";
import DownloadCSV from "../DownloadCSV";
import Spinner from "../Spinner/Spinner";
import ClickOutside from "../ClickOutside";
import { Link } from "react-router-dom";
import { IoCloudDownloadOutline } from "react-icons/io5";
import DownloadXLSX from "../DownloadXLSX";

type Props = {};

const ParkingOccupanyReportForm = (props: Props) => {
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const [disableHelper, setDisableHelper] = useState<boolean>(false);
  const [granularity, setGranularity] = useState<number>(6);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(10, "d").format("DD-MM-YYYY").toString()
  );
  const [endDate, setEndDate] = useState<string>(
    moment().format("DD-MM-YYYY").toString()
  );
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Parking[]>([]);
  const [report, setReport] = useState<ParkingAverageOccupancyReport[]>();
  const [parkings, setParkings] = useState<Parking[]>();
  const handleDateRangeChange = (values: any[]) => {
    setStartDate(moment(values[0]).format("DD-MM-YYYY").toString());
    setEndDate(moment(values[1]).format("DD-MM-YYYY").toString());
  };

  useEffect(() => {
    const filteredResults = parkings?.filter(
      (parking) =>
        parking.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        parking.manager
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
    );
    setSearchResults(filteredResults ? filteredResults : []);
  }, [parkings, search]);

  useEffect(() => {
    const getParkings = async () => {
      const result = await getParkingList();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setParkings(result.data.results);
      }
    };
    getParkings();
  }, []);

  const getReport = async () => {
    setIsReportLoaded(false);
    const result = await getParkingAverageOccupancyReport(
      `?granularity=${
        granularity * 60
      }&start-date=${startDate}&end-date=${endDate}`
    );
    if (typeof result === "string") {
    } else if (Array.isArray(result.data)) {
      setReport(result.data);
      setIsReportLoaded(true);
    }
  };

  return (
    <>
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Raport zajętości parkingów
          </h3>
        </div>
        <div className="flex flex-col gap-5.5 p-6.5">
          <div>
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Szczegółowość (granulacja - w godzinach)
            </label>
            <div className="relative">
              <input
                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                type="number"
                placeholder="granularity"
                defaultValue={granularity}
                onChange={(e) => {
                  e.preventDefault();
                  setGranularity(Number(e.target.value));
                }}
              />
              <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center"></div>
            </div>
          </div>
          <DatePickerRange
            handleDateRangeChange={handleDateRangeChange}
            title={"Zakres dat (domyślnie: ostatnie 10 dni)"}
          />

          <button
            className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
            type="submit"
            onClick={(e) => {
              getReport();
              setDisableHelper(true);
              e.preventDefault();
            }}
          >
            Odśwież dane
          </button>
        </div>
      </div>
      {isReportLoaded ? (
        <>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-50 pr-50">
            <input
              className="text-black dark:text-white bg-white dark:bg-boxdark dark:border-strokedark w-full pr-11 h-10 pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
              placeholder="Filtruj parkingi"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <ClickOutside
              onClick={() => setDropdownOpen(false)}
              className="relative"
            >
              <Link
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center"
                to="#"
              >
                <IoCloudDownloadOutline
                  className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current}`}
                />
              </Link>
              {dropdownOpen && (
                <div
                  className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark`}
                >
                  <ul className="flex flex-col gap-1 border-b border-stroke dark:border-strokedark">
                    <li>
                      <DownloadCSV
                        data={report?.map((el) => ({
                          ...el,
                          avg_free_places: Math.round(el.avg_free_places),
                          avg_taken_places: Math.round(el.avg_taken_places),
                          avg_occupancy: (el.avg_occupancy / 100)
                            .toFixed(4)
                            .replace(".", ","),
                        }))}
                        fileName={`raport_zajętości_parkingów_${moment()
                          .format("DD_MM_YYYY_HH_mm_ss")
                          .toString()}`}
                        headers="ID Parkingu,Parking,Całkowita libcza miejsc,Czasookres,Średnia miejsc wolnych,Średnia miejsc zajętych,Średnia zajętość parkingu"
                      />
                    </li>
                    <li>
                      <DownloadXLSX
                        data={report?.map((el) => ({
                          ...el,
                          avg_free_places: Math.round(el.avg_free_places),
                          avg_taken_places: Math.round(el.avg_taken_places),
                          avg_occupancy: (el.avg_occupancy / 100)
                            .toFixed(4)
                            .replace(".", ","),
                        }))}
                        fileName={`raport_zajętości_parkingów_${moment()
                          .format("DD_MM_YYYY_HH_mm_ss")
                          .toString()}`}
                        headers={[
                          [
                            "ID Parkingu",
                            "Parking",
                            "Całkowita libcza miejsc",
                            "Czasookres",
                            "Średnia miejsc wolnych",
                            "Średnia miejsc zajętych",
                            "Średnia zajętość parkingu",
                          ],
                        ]}
                      />
                    </li>
                  </ul>
                </div>
              )}
            </ClickOutside>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <div className="text-sm text-slate-500">
              Wyświetlane parkingi: <b>{searchResults.length}</b> z dostępnych{" "}
              {parkings?.length}
            </div>
            <div className="text-sm text-slate-500">
              Zakres dat: <b>{startDate}</b> - <b>{endDate}</b>
            </div>

            {searchResults?.map((parking, key) => (
              <div key={key}>
                <p className="relative center">
                  Nazwa parkingu: <b>{parking.name}</b>
                </p>
                <p>
                  Zarządca: <b>{parking.manager}</b>
                </p>
                <p>
                  Ogólna liczba miejsc parkingowych:{" "}
                  <b>{parking.total_standard_places}</b>
                </p>
                <>
                  {" "}
                  <ParkingUsageChart
                    report={report!.filter((park) => {
                      return park.parking_id == parking.id;
                    })}
                  />
                </>
              </div>
            ))}
          </div>
        </>
      ) : disableHelper ? (
        <>
          <div className="text-sm text-slate-500">generuję raport</div>
          <div className="text-sm text-slate-500">
            Zakres dat: <b>{startDate}</b> - <b>{endDate}</b>
          </div>
          <div className="text-sm text-slate-500">
            Granulacja: <b>{granularity} godzin.</b>
          </div>
          <Spinner />
        </>
      ) : (
        <p>Wygeneruj raport żeby zobaczyć podgląd</p>
      )}
    </>
  );
};
export default ParkingOccupanyReportForm;
