import React from "react";
import { BsFiletypeCsv } from "react-icons/bs";
type Props = {
  data: any;
  fileName: any;
  headers?: string;
};

const DownloadCSV = ({ data, fileName, headers = "" }: Props) => {
  const convertToCSV = (objArray: any) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = headers + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
      type="button"
      onClick={downloadCSV}
      data-html2canvas-ignore={true}
    >
      <BsFiletypeCsv />
      csv
    </button>
  );
};

export default DownloadCSV;
