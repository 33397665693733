import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../models/User";
import { deleteUser, editUser, getUser } from "../../services/UserService";
import Spinner from "../Spinner/Spinner";

type Props = {};

const EditUserForm = (props: Props) => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (e.target.form[2].value.localeCompare(e.target.form[3].value)) {
      toast.warning("podane hasła się nie zgadzają");
    } else {
      await editUser(userId!, e.target.form[2].value).then((res) => {
        if (res) {
          toast.success("Hasło zostało zaktualizowane");
          navigate("/users");
        }
      });
    }
  };
  const handleDelete = async (e: any) => {
    e.preventDefault();

    await deleteUser(userId!).then((res) => {
      if (res) {
        toast.warning("Użytkownik został usunięty");
        navigate("/users");
      }
    });
  };
  const [user, setUser] = useState<User>();
  const [apiError, setApiError] = useState<string | null>(null);
  useEffect(() => {
    const getVmsInit = async () => {
      const result = await getUser(userId!);
      if (typeof result === "string") {
        setApiError(result);
      } else if (result.data.result) {
        setUser(result.data.result);
      }
    };
    getVmsInit();
  }, []);
  return (
    <>
      {user && !apiError ? (
        <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
          <div className="flex flex-col gap-9">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Edytuj użytkownika
                </h3>
              </div>
              <form>
                <div className="p-6.5">
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        email<span className="text-meta-1">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="email"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        required
                        defaultValue={user.email}
                        disabled={true}
                      />
                    </div>

                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        login<span className="text-meta-1">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="login"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        required
                        defaultValue={user.username}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      hasło<span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="hasło"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                    />
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      powtórz hasło<span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="hasło"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                    />
                  </div>

                  <button
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Zmień hasło
                  </button>
                  <div className="block mb-4 mx-auto border-b border-slate-300 pb-2 max-w-[360px]"></div>
                  <button
                    onDoubleClick={handleDelete}
                    type="submit"
                    className="flex justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Usuń
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : apiError ? (
        <>
          Użytkownik <b>{userId}</b> nie istnieje
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default EditUserForm;
