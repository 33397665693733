import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardDataStats from "../CardDataStats";
import {
  getParking,
  getParkingAverageOccupancyReport,
} from "../../services/ParkingService";
import { Parking, ParkingAverageOccupancyReport } from "../../models/Parking";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import ParkingUsageChart from "../Charts/ParkingUsageChart";
import VmsesTable from "../Tables/VmsesTable";

type Props = {};

const ParkingDetails = (props: Props) => {
  let { parkingId } = useParams();
  const [parking, setParking] = useState<Parking>();
  const [parkingOccupancyReport, setParkingOccupancyReport] = useState<
    ParkingAverageOccupancyReport[]
  >([]);
  const [apiError, setApiError] = useState<string | null>(null);
  const startDate = moment().subtract(10, "d").format("DD-MM-YYYY").toString();
  const endDate = moment().format("DD-MM-YYYY").toString();

  useEffect(() => {
    const getParkingInit = async () => {
      const result = await getParking(parkingId!);
      if (typeof result === "string") {
        setApiError(result);
      } else if (result.data.parking) {
        setParking(result.data.parking);
      }
    };
    const getParkingOccupancyReportInit = async () => {
      const result = await getParkingAverageOccupancyReport(
        `?granularity=15&start-date=${startDate}&end-date=${endDate}&parkings=${parkingId}`
      );
      if (typeof result === "string") {
      } else if (Array.isArray(result.data)) {
        setParkingOccupancyReport(result.data);
      }
    };
    getParkingInit();
    getParkingOccupancyReportInit();
  }, []);
  return (
    <>
      {parking && !apiError ? (
        <>
          <div className="mb-5 flex items-center justify-between"></div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <CardDataStats title="Nazwa parkingu" value={parking?.name} />
            <CardDataStats title="Zarządca" value={parking?.manager} />
            <CardDataStats
              title="Numer kontaktowy"
              value={parking.managerTelephone}
            />
            <CardDataStats title="Operator" value={parking.operatorTelephone} />
            <CardDataStats
              title="Ilość miejsc parkingowych"
              value={parking?.total_standard_places.toString()}
            />
            <CardDataStats
              title="Ilość wolnych"
              value={parking?.free_places_total?.public.toString()}
            />
            <CardDataStats
              title="Zajętość parkingu"
              value={
                Math.round(parking.parking_occupancy_public).toString() + "%"
              }
            />
            <CardDataStats
              title="Stan na"
              value={moment(parking.free_places_total?.time_updated).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
            />
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <ParkingUsageChart report={parkingOccupancyReport} />
          </div>
          <div className="block mb-4 mx-auto border-b border-slate-300 pb-2 max-w-[360px]"></div>
          <div>
            <VmsesTable parkingId={parking.id} />
          </div>
        </>
      ) : !apiError ? (
        <Spinner />
      ) : (
        <>
          Parking z id <b>{parkingId}</b> nie istnieje
        </>
      )}
    </>
  );
};

export default ParkingDetails;
