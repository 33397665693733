import React, { useEffect, useState } from "react";
import { Vms, VmsStatusHistoryReport } from "../../models/Vms";
import {
  getVmses,
  getVmsStatusReportHistory,
} from "../../services/ParkingService";
import VmsStatusHistoryChart from "../Charts/VmsStatusHistoryChart";
import moment from "moment";

type Props = {};
const VmsStatusHistoryReportForm = (props: Props) => {
  const startDate = moment().subtract(10, "d").format("DD-MM-YYYY").toString();
  const endDate = moment().format("DD-MM-YYYY").toString();
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Vms[]>([]);
  const [vmses, setVmses] = useState<Vms[]>();
  const [statusHistoryReport, setStatusHistoryReport] =
    useState<VmsStatusHistoryReport[]>();
  useEffect(() => {
    const filteredResults = vmses?.filter(
      (vms) =>
        vms.address
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        vms.parking.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        vms.status.display_value
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
    );
    setSearchResults(filteredResults ? filteredResults : []);
  }, [vmses, search]);
  useEffect(() => {
    const getVmsesInit = async () => {
      const result = await getVmses("");
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setVmses(result.data.results);
      }
    };
    const getVmsStatusHistoryInit = async () => {
      const result = await getVmsStatusReportHistory(
        `?start-date=${startDate}&end-date=${endDate}&limit=100000`
      );
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setStatusHistoryReport(result.data.results);
      }
    };
    getVmsesInit();
    getVmsStatusHistoryInit();
  }, []);
  return (
    <>
      {statusHistoryReport ? (
        <>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-50 pr-50">
            <input
              className="text-black dark:text-white bg-white dark:bg-boxdark dark:border-strokedark w-full pr-11 h-10 pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
              placeholder="Filtruj TIP"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <div className="text-sm text-slate-500">
              Wyświetlane TIP: <b>{searchResults.length}</b> z dostępnych{" "}
              {vmses?.length}
            </div>
            {searchResults
              ?.sort((a, b) => a.id - b.id)
              .map((vms, key) => (
                <div key={key}>
                  <p className="relative center">
                    Numer TIP: <b>{vms.id}</b>, Nazwa TIP: <b>{vms.address}</b>,
                    Adres IP: <b>{vms.ip_address}</b>, Status:{" "}
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                        vms.status.status === "online"
                          ? "bg-success text-success"
                          : vms.status.status === "offline"
                          ? "bg-danger text-danger"
                          : vms.status.status === "new"
                          ? "bg-info text-info"
                          : "bg-warning text-warning"
                      }`}
                    >
                      {vms.status.display_value}
                    </p>
                  </p>
                  <>
                    {" "}
                    <VmsStatusHistoryChart
                      report={statusHistoryReport!.filter((hist) => {
                        return hist.vms_id == vms.id;
                      })}
                    />
                  </>
                </div>
              ))}
          </div>
        </>
      ) : (
        <p></p>
      )}
    </>
  );
};
export default VmsStatusHistoryReportForm;
