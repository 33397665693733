import moment from "moment";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinner/Spinner";
import { GoArrowRight } from "react-icons/go";
import Pagination from "@mui/material/Pagination";
import {
  getVmsStatusReportHistory,
  VmsStatusHistoryReportResponse,
} from "../../services/ParkingService";

type Props = {
  vmsId: string;
};

const VmsStatusHistoryTable = (props: Props) => {
  const [vmsStatusHistoryReport, setVmsStatusHistoryReport] =
    useState<VmsStatusHistoryReportResponse>();
  const [limit, setLimit] = useState(4);
  const [start, setStart] = useState(1);

  useEffect(() => {
    const getVmsStatusHistoryInit = async () => {
      const result = await getVmsStatusReportHistory(
        `?vmses=${props.vmsId}&start=${start}&limit=${limit}&reverse-order=true`
      );
      if (typeof result === "string") {
      } else {
        setVmsStatusHistoryReport(result.data);
      }
    };
    getVmsStatusHistoryInit();
  }, [limit, start]);
  return (
    <>
      {vmsStatusHistoryReport ? (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="py-4 px-4 font-medium text-black dark:text-white">
                    Status
                  </th>
                  <th className="py-4 px-4 font-medium text-black dark:text-white">
                    Data
                  </th>
                </tr>
              </thead>
              <tbody>
                {vmsStatusHistoryReport?.results.map(
                  (vmsStatusHistory, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p
                          className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                            vmsStatusHistory.status === "online"
                              ? "bg-success text-success"
                              : vmsStatusHistory.status === "offline"
                              ? "bg-danger text-danger"
                              : vmsStatusHistory.status === "new"
                              ? "bg-info text-info"
                              : "bg-warning text-warning"
                          }`}
                        >
                          {vmsStatusHistory.status_displayed_value}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {moment(vmsStatusHistory.timestamp).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <div className="flex justify-between items-center px-4 py-3">
              <div className="text-sm text-slate-500">
                Wyświetlam{" "}
                <b>
                  {start} - {start - 1 + vmsStatusHistoryReport.results.length}
                </b>{" "}
                z {vmsStatusHistoryReport.count}
              </div>
              <div className="flex space-x-1">
                <Pagination
                  count={vmsStatusHistoryReport.page_count}
                  onChange={(e, value) => setStart((value - 1) * limit + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};
export default VmsStatusHistoryTable;
