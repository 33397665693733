import React, { useEffect, useState } from "react";
import { Parking } from "../../models/Parking";
import { getParkingList } from "../../services/ParkingService";
import Spinner from "../Spinner/Spinner";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Link } from "react-router-dom";
import moment from "moment";

type Props = {};

const ParkingsTable: React.FC<{}> = ({}) => {
  const [parkings, setParkings] = useState<Parking[]>();
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Parking[]>([]);
  useEffect(() => {
    const getParkingsInit = async () => {
      const result = await getParkingList();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setParkings(result.data.results);
      }
    };
    getParkingsInit();
  }, []);
  useEffect(() => {
    const filteredResults = parkings?.filter(
      (parking) =>
        parking.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        parking.manager
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
    );
    setSearchResults(filteredResults ? filteredResults : []);
  }, [parkings, search]);
  return (
    <>
      {parkings ? (
        <>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-5 pr-6 xl:pl-50 xl:pr-50">
            <input
              className="w-full rounded border-[1.5px] border-stroke bg-white py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              placeholder="Filtruj parkingi"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      Parking
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      Zarządca
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      Zajętość parkingu
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Liczba znaków
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Stan na
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((parking, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <Link to={parking.id}>
                          <h5 className="font-medium text-black dark:text-white">
                            {parking.name}
                          </h5>
                        </Link>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {parking.manager}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <span className="text-black dark:text-white">
                          {parking.parking_occupancy_public ||
                          parking.parking_occupancy_public === 0 ? (
                            <>
                              <Gauge
                                width={90}
                                height={90}
                                startAngle={-110}
                                endAngle={110}
                                value={Math.round(
                                  parking.parking_occupancy_public
                                )}
                                text={({ value }) => `${value}%`}
                                sx={{
                                  [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: 18,
                                    transform: "translate(0px, 0px)",
                                  },
                                }}
                              />
                            </>
                          ) : (
                            <>BRAK DANYCH</>
                          )}
                        </span>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {parking.vmses.length}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {parking.free_places_total ? (
                            <>
                              {moment(
                                parking.free_places_total?.time_updated
                              ).format("DD-MM-YYYY HH:mm:ss")}
                            </>
                          ) : (
                            <>BRAK DANYCH</>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center px-4 py-3">
                <div className="text-sm text-slate-500">
                  Wyświetlam <b>{searchResults.length}</b> z {parkings.length}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default ParkingsTable;
