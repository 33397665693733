import { BsFiletypeXlsx } from "react-icons/bs";
import * as XLSX from "xlsx";
type Props = {
  data: any;
  fileName: any;
  headers: string[][];
};

const DownloadXLSX = ({ data, fileName, headers }: Props) => {
  const DownloadXLSX = () => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, headers);
    XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Raport");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  return (
    <button
      className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
      type="button"
      onClick={DownloadXLSX}
      data-html2canvas-ignore={true}
    >
      <BsFiletypeXlsx />
      excel
    </button>
  );
};

export default DownloadXLSX;
