import React, { useEffect, useState } from "react";
import { NotificationEmail } from "../../models/NotificationEmails";
import {
  addNotificationEmail,
  deleteNotificationEmail,
  getNotificationEmails,
} from "../../services/NotificationEmailService";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";
import { toast } from "react-toastify";

type Props = {};

const NotificationRecipientsTable = (props: Props) => {
  const [newRecipient, setNewRecipient] = useState<string>("");
  const [refreshMailingList, setRefreshMailingList] = useState<boolean>(false);
  const [notificationEmails, setNotificationEmails] =
    useState<NotificationEmail[]>();
  useEffect(() => {
    const getNotificationEmailsInit = async () => {
      const result = await getNotificationEmails();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setNotificationEmails(result.data.results);
      }
    };
    getNotificationEmailsInit();
  }, [refreshMailingList]);

  const handleDelete = async (id: any) => {
    await deleteNotificationEmail(id!).then((res) => {
      if (res) {
        toast.warning("Email został usunięty z listy mailingowej");
      }
    });
    setRefreshMailingList(!refreshMailingList);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await addNotificationEmail(e.target.form[0].value).then((res) => {
      if (res) {
        toast.success("Email został dodany pomyślnie");
        setRefreshMailingList(!refreshMailingList);
        setNewRecipient("");
      }
    });
  };

  return (
    <>
      {notificationEmails && notificationEmails.length > 0 ? (
        <>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      Id
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      Email
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Data utworzenia
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      Akcje
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white"></th>
                  </tr>
                </thead>
                <tbody>
                  {notificationEmails.map((notificationEmail, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {notificationEmail.id}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {notificationEmail.email}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {moment(notificationEmail.time_created).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            onDoubleClick={(e: any) => {
                              e.preventDefault();
                              handleDelete(notificationEmail.id);
                            }}
                          >
                            <MdDeleteForever />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : notificationEmails?.length == 0 ? (
        <>Lista mailingowa jest pusta</>
      ) : (
        <>
          <Spinner />
        </>
      )}
      <form>
        <div className="p-6.5">
          <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
            <div className="w-full">
              <label className="mb-2.5 block text-black dark:text-white">
                email
              </label>
              <input
                type="email"
                placeholder="email"
                className="w-full rounded border-[1.5px] border-stroke bg-white py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                value={newRecipient}
                required
                onChange={(e: any) => {
                  setNewRecipient(e.target.value);
                }}
              />
            </div>
          </div>

          <button
            className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
            type="submit"
            onClick={handleSubmit}
          >
            Dodaj email
          </button>
        </div>
      </form>
    </>
  );
};

export default NotificationRecipientsTable;
