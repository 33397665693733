import React, { useEffect, useState } from "react";
import { getVmses } from "../../services/ParkingService";
import Spinner from "../Spinner/Spinner";
import { Link } from "react-router-dom";
import moment from "moment";
import { Vms } from "../../models/Vms";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

type Props = { parkingId?: string };

const VmsesTable = (props: Props) => {
  const [vmses, setVmses] = useState<Vms[]>();
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Vms[]>([]);
  useEffect(() => {
    const getVmsesInit = async () => {
      const result = await getVmses(
        props.parkingId ? `?parking=${props.parkingId}` : ""
      );
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setVmses(result.data.results);
      }
    };
    getVmsesInit();
  }, []);
  useEffect(() => {
    const filteredResults = vmses?.filter(
      (vms) =>
        vms.address
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        vms.parking.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          ) ||
        vms.status.display_value
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
    );
    setSearchResults(filteredResults ? filteredResults : []);
  }, [vmses, search]);

  return (
    <>
      {vmses && vmses.length > 0 ? (
        <>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-5 pr-6 xl:pl-50 xl:pr-50">
            <input
              className="w-full rounded border-[1.5px] border-stroke bg-white py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              placeholder="Filtruj wyświetlacze"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>

          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      Lokalizacja
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      Parking
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      Status
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Wyświetlana wartość
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Bateria
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      Stan na
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((vms, key) => (
                    <tr key={key}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <Link to={`/vmses/${vms.id.toString()}`}>
                          <p className="text-black dark:text-white">
                            {vms.address}
                          </p>
                        </Link>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          <Link to={`/parkings/${vms.parking.id}`}>
                            {vms.parking.name}
                          </Link>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p
                          className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                            vms.status.status === "online"
                              ? "bg-success text-success"
                              : vms.status.status === "offline"
                              ? "bg-danger text-danger"
                              : vms.status.status === "new"
                              ? "bg-info text-info"
                              : "bg-warning text-warning"
                          }`}
                        >
                          {vms.status.display_value}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {vms.status.status === "online"
                            ? vms.displayed_content
                            : ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <span className="text-black dark:text-white">
                          {vms.battery_level || vms.battery_level === 0 ? (
                            <>
                              <Gauge
                                width={90}
                                height={90}
                                startAngle={-110}
                                endAngle={110}
                                value={Math.round(vms.battery_level)}
                                text={({ value }) => `${value}%`}
                                sx={{
                                  [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: 18,
                                    transform: "translate(0px, 0px)",
                                  },
                                }}
                              />
                            </>
                          ) : (
                            <>BRAK DANYCH</>
                          )}
                        </span>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {moment(
                            vms.time_updated
                              ? vms.time_updated
                              : vms.time_created
                          ).format("DD-MM-YYYY HH:mm:ss")}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between items-center px-4 py-3">
                <div className="text-sm text-slate-500">
                  Wyświetlam <b>{searchResults.length}</b> z {vmses.length}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : vmses?.length === 0 ? (
        <>Brak znaków</>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default VmsesTable;
