import axios from "axios";
import { Parking, ParkingAverageOccupancyReport } from "../models/Parking";
import axiosInstance from "./AxiosInstance";
import { ApiLogsResponse } from "../models/ApiLogs";
import {
  Vms,
  VmsStatusHistoryReport,
  VmsVoltageHistoryReport,
} from "../models/Vms";
import { handleError } from "../helpers/ErrorHandler";
import { UpdateParkingsResponse } from "../models/Sync";
import { toast } from "react-toastify";

interface ParkingsResponse {
  results: Parking[];
}

interface ParkingResponse {
  parking: Parking;
}

interface ParkingAverageOccupancyReportResponse {
  results: ParkingAverageOccupancyReport[];
}

interface VmsesResponse {
  results: Vms[];
}

interface VmsResponse {
  vms: Vms;
}

interface VmsVoltageHistoryReportResponse {
  results: VmsVoltageHistoryReport[];
}

export interface VmsStatusHistoryReportResponse {
  count: number;
  limit: number;
  page_count: number;
  results: VmsStatusHistoryReport[];
}

interface DeleteVmsResponse {
  result: string;
}

interface ChangeVmsStateResponse {
  Status: string;
}

interface FetchVmsVoltageResponse {
  voltage: number;
}

export const getParkingList = async () => {
  try {
    const data = await axiosInstance.get<ParkingsResponse>("/api/parkings");
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getParking = async (parkingId: string) => {
  try {
    const data = await axiosInstance.get<ParkingResponse>(
      `/api/parkings/${parkingId}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getParkingAverageOccupancyReport = async (query: string) => {
  try {
    const data = await axiosInstance.get<ParkingAverageOccupancyReportResponse>(
      `/api/reports/parking-average-occupancy-report${query}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getApiLogs = async (query: string) => {
  try {
    const data = await axiosInstance.get<ApiLogsResponse>(
      `/api/logs/api-client-logs${query}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getVmses = async (query: string) => {
  try {
    const data = await axiosInstance.get<VmsesResponse>(`/api/vmses${query}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getVms = async (vmsId: string) => {
  try {
    const data = await axiosInstance.get<VmsResponse>(`/api/vmses/${vmsId}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const addVms = async (
  id: number,
  ip_address: string,
  address: string,
  latitude: number,
  longitude: number,
  parking_id: string
) => {
  try {
    const data = await axiosInstance.post<VmsResponse>("/api/vmses", {
      id: id,
      ip_address: ip_address,
      address: address,
      parking_id: parking_id,
      latitude: latitude ? latitude : null,
      longitude: longitude ? longitude : null,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
};
export const editVms = async (
  vmsId: string,
  id: number,
  ip_address: string,
  address: string,
  latitude: number,
  longitude: number,
  parking_id: string
) => {
  try {
    const data = await axiosInstance.put<VmsResponse>(`/api/vmses/${vmsId}`, {
      id: id,
      ip_address: ip_address,
      address: address,
      parking_id: parking_id,
      latitude: latitude ? latitude : null,
      longitude: longitude ? longitude : null,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const getVmsVoltageReportHistory = async (query: string) => {
  try {
    const data = await axiosInstance.get<VmsVoltageHistoryReportResponse>(
      `/api/reports/vms-voltage-history-report${query}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getVmsStatusReportHistory = async (query: string) => {
  try {
    const data = await axiosInstance.get<VmsStatusHistoryReportResponse>(
      `/api/reports/vms-status-history-report${query}`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.message;
    } else {
      return "An unexpected error has occured.";
    }
  }
};

export const getSyncParkingMetadata = async () => {
  try {
    console.log("here");
    const data = await axiosInstance.get<UpdateParkingsResponse>(
      "/api/sync/parkings-metadata"
    );
    toast.success(
      "Sukces" +
        "\nzaktualizowane: " +
        data.data.updated +
        "\nnowe parkingi: " +
        data.data.created
    );

    return data;
  } catch (error) {
    handleError(error);
  }
};

export const getSyncVmsesDisplayedValue = async () => {
  try {
    const data = await axiosInstance.get<UpdateParkingsResponse>(
      "/api/sync/vmses-display-value"
    );
    toast.success("wyświetlacze zaktualizowane");

    return data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteVms = async (vmsId: string) => {
  try {
    const data = await axiosInstance.delete<DeleteVmsResponse>(
      `/api/vmses/${vmsId}`
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const disableVms = async (vmsId: string) => {
  try {
    const data = await axiosInstance.get<ChangeVmsStateResponse>(
      `/api/vmses/disable-vms/${vmsId}`
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const enableVms = async (vmsId: string) => {
  try {
    const data = await axiosInstance.get<ChangeVmsStateResponse>(
      `/api/vmses/enable-vms/${vmsId}`
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchVmsVoltage = async (vmsId: string) => {
  try {
    const data = await axiosInstance.get<FetchVmsVoltageResponse>(
      `/api/vmses/voltage/${vmsId}`
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
