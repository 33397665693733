import ReactApexChart from "react-apexcharts";
import Spinner from "../Spinner/Spinner";
import apexOptions from "./ChartParkingOptions";
import { ParkingAverageOccupancyReport } from "../../models/Parking";

type Props = {
  report: ParkingAverageOccupancyReport[];
};

const ParkingUsageChart = ({ report }: Props) => {
  const dataSet = report?.map((entry) => {
    return {
      x: new Date(entry.timestamp)!,
      y: Math.round(entry.avg_occupancy),
    };
  });

  const input = dataSet ? dataSet : [{ x: 0, y: 0 }];
  const data = [
    {
      name: "średnia zajętość parkingu",
      data: input,
    },
  ];

  return (
    <>
      {report ? (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
          <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
            <div className="flex w-full flex-wrap gap-3 sm:gap-5">
              <div className="flex min-w-47.5"></div>
            </div>
            <div className="flex w-full max-w-45 justify-end"></div>
          </div>

          <div>
            <div id="parkingUsageHistoryChart" className="-ml-5">
              <ReactApexChart
                options={apexOptions}
                series={data}
                type="area"
                height={350}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default ParkingUsageChart;
