import { handleAuthError } from "../helpers/AuthErrorHandler";
import { handleError } from "../helpers/ErrorHandler";
import { UserProfileToken } from "../models/User";
import axiosInstance from "./AxiosInstance";

export const loginAPI = async (username: string, password: string) => {
  try {
    const data = await axiosInstance.post<UserProfileToken>("/auth/login", {
      username: username,
      password: password,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const registerAPI = async (
  email: string,
  username: string,
  password: string,
  roles: number[]
) => {
  try {
    const data = await axiosInstance.post<UserProfileToken>("/auth/register", {
      email: email,
      username: username,
      password: password,
      roles: roles,
    });
    return data;
  } catch (error) {
    handleAuthError(error);
  }
};

export const revokeTokenAPI = async (
  tokenType: string,
  token: string | null
) => {
  if (token) {
    try {
      const data = await axiosInstance.delete(`/auth/revoke_${tokenType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      handleError(error);
    }
  }
};
