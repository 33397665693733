import React, { useEffect, useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import Spinner from "../../Spinner/Spinner";
import { Role } from "../../../models/User";
import { getRoles } from "../../../services/UserService";

type Props = { selectedRoles?: number[] };

const SelectGroupRoles = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState<number>();
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[]>();
  useEffect(() => {
    const getRolesInit = async () => {
      const result = await getRoles();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setRoles(result.data.results);
        setSelectedOption(props.selectedRoles ? props.selectedRoles[0] : 2);
      }
    };
    getRolesInit();
  }, []);

  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  return (
    <>
      {roles ? (
        <div className="mb-4.5">
          <label className="mb-2.5 block text-black dark:text-white">
            {" "}
            Rola{" "}
          </label>

          <div className="relative z-20 bg-transparent dark:bg-form-input">
            <select
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(parseInt(e.target.value));
                changeTextColor();
              }}
              className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                isOptionSelected ? "text-black dark:text-white" : ""
              }`}
            >
              <option
                value=""
                disabled
                className="text-body dark:text-bodydark"
              >
                Wybierz role
              </option>
              {roles.map((role, key) => (
                <option
                  key={role.id}
                  value={role.id}
                  className="text-body dark:text-bodydark"
                >
                  {role.name}
                </option>
              ))}
            </select>

            <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
              <SlArrowDown />
            </span>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default SelectGroupRoles;
