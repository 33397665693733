import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/User";
import { updatePassword, whoAmI } from "../../services/UserService";
import Spinner from "../Spinner/Spinner";

type Props = {};

const ChangePasswordForm = (props: Props) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<User>();
  const [apiError, setApiError] = useState<string | null>(null);
  useEffect(() => {
    const whoAmIInit = async () => {
      const result = await whoAmI();
      if (typeof result === "string") {
        setApiError(result);
      } else if (result.data.result) {
        setUser(result.data.result);
      }
    };
    whoAmIInit();
  }, []);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (e.target.form[1].value.localeCompare(e.target.form[2].value)) {
      toast.warning("wprowadzone hasła się nie zgadzają");
    } else {
      await updatePassword(e.target.form[0].value, e.target.form[1].value).then(
        (res) => {
          if (res) {
            toast.success("Hasło zostało zaktualizowane");
            navigate("/");
          }
        }
      );
    }
  };
  return (
    <>
      {user && !apiError ? (
        <div className="grid grid-cols-1 gap-9 sm:grid-cols-1">
          <div className="flex flex-col gap-9">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Zmień hasło
                </h3>
              </div>
              <form>
                <div className="p-6.5">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Aktualne hasło<span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="aktualne hasło"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                    />
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Nowe hasło<span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="nowe hasło"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                    />
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Potwierdź hasło<span className="text-meta-1">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder="potwierdź hasło"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      required
                    />
                  </div>

                  <button
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Zmień hasło
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : apiError ? (
        <>wystąpił błąd, spróbuj ponownie później.</>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ChangePasswordForm;
