import React, { ReactNode } from "react";

interface CardDataStatsProps {
  title: string;
  value: string;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({ title, value }) => {
  return (
    <div className="rounded-sm border border-stroke bg-white py-3 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex items-end justify-between">
        <div>
          <span className="text-sm font-medium">{title}</span>
          <h4 className="text-title-sm font-bold text-black dark:text-white">
            {value ? value : "brak danych"}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default CardDataStats;
