import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import React from "react";
import { UserProfile } from "../models/User";
import { loginAPI, registerAPI, revokeTokenAPI } from "../services/AuthService";

type UserContextType = {
  user: UserProfile | null;
  accessToken: string | null;
  registerUser: (
    email: string,
    username: string,
    password: string,
    roles: number[]
  ) => void;
  loginUser: (username: string, password: string) => void;
  logout: () => void;
  isLoggedIn: () => boolean;
  isAdmin: () => boolean;
  isUser: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const accessToken = localStorage.getItem("accessToken");
    if (user && accessToken) {
      setUser(JSON.parse(user));
      setAccessToken(accessToken);
    }
    setIsReady(true);
  }, []);

  const registerUser = async (
    email: string,
    username: string,
    password: string,
    roles: number[]
  ) => {
    await registerAPI(email, username, password, roles)
      .then((res) => {
        if (res) {
          localStorage.setItem("accessToken", res?.data.access_token);
          const userObj = {
            username: res?.data.user.username,
            email: res?.data.user.email,
            roles: res?.data.user.roles,
            time_created: res?.data.user.time_created,
          };
          localStorage.setItem("user", JSON.stringify(userObj));
          setAccessToken(res?.data.access_token!);
          setUser(userObj!);
          toast.success("Użytkownik został utworzony");
          navigate("/");
        }
      })
      .catch((e) => toast.warning("Server error occured"));
  };

  const loginUser = async (username: string, password: string) => {
    await loginAPI(username, password)
      .then((res) => {
        if (res) {
          localStorage.setItem("accessToken", res?.data.access_token);
          const userObj = {
            username: res?.data.user.username,
            email: res?.data.user.email,
            roles: res?.data.user.roles,
            time_created: res?.data.user.time_created,
          };
          localStorage.setItem("user", JSON.stringify(userObj));
          setAccessToken(res?.data.access_token!);
          setUser(userObj!);
          toast.success("Zalogowano pomyślnie");
          navigate("/");
        }
      })
      .catch((e) => toast.warning("Server error occured"));
  };

  const isLoggedIn = () => {
    return !!user;
  };

  const isAdmin = () => {
    if (user?.roles) {
      return user.roles.indexOf("Administrator") > -1;
    }
    return false;
  };

  const isUser = () => {
    if (user?.roles) {
      return user.roles.indexOf("User") > -1;
    }
    return false;
  };

  const logout = async () => {
    await revokeTokenAPI("access", accessToken)
      .then((res) => {
        if (res) {
        }
      })
      .catch((e) => toast.warning("Server error occured"));

    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    setUser(null);
    setAccessToken("");
    navigate("/login");
  };

  return (
    <UserContext.Provider
      value={{
        loginUser,
        user,
        accessToken,
        registerUser,
        isLoggedIn,
        logout,
        isAdmin,
        isUser,
      }}
    >
      {isReady ? children : null}
    </UserContext.Provider>
  );
};

export const useAuth = () => React.useContext(UserContext);
