import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardDataStats from "../CardDataStats";
import {
  deleteVms,
  disableVms,
  enableVms,
  getVms,
  getVmsStatusReportHistory,
  getVmsVoltageReportHistory,
} from "../../services/ParkingService";

import Spinner from "../Spinner/Spinner";
import {
  Vms,
  VmsStatusHistoryReport,
  VmsVoltageHistoryReport,
} from "../../models/Vms";
import VmsStatusHistoryTable from "../Tables/VmsStatusHistoryTable";
import VmsVoltageHistoryChart from "../Charts/VmsVoltageHistoryChart";
import Button from "@mui/material/Button";
import VmsStatusHistoryChart from "../Charts/VmsStatusHistoryChart";
import { toast } from "react-toastify";
import { useAuth } from "../../context/useAuth";
import moment from "moment";

type Props = {};

const VmsDetails = (props: Props) => {
  const { isAdmin } = useAuth();
  const startDate = moment().subtract(5, "d").format("DD-MM-YYYY").toString();
  const endDate = moment().format("DD-MM-YYYY").toString();
  const granularity = 15;
  const navigate = useNavigate();
  let { vmsId } = useParams();

  const [vms, setVms] = useState<Vms>();
  const [voltageHistoryReport, setVoltageHistoryReport] =
    useState<VmsVoltageHistoryReport[]>();
  const [statusHistoryReport, setStatusHistoryReport] =
    useState<VmsStatusHistoryReport[]>();
  const [apiError, setApiError] = useState<string | null>(null);

  useEffect(() => {
    const getVmsInit = async () => {
      const result = await getVms(vmsId!);
      if (typeof result === "string") {
        setApiError(result);
      } else if (result.data.vms) {
        setVms(result.data.vms);
      }
    };
    const getVmsVoltageReportHistoryInit = async () => {
      const result = await getVmsVoltageReportHistory(
        `?granularity=${granularity}&start-date=${startDate}&end-date=${endDate}&vmses=${vmsId}&limit=100000`
      );
      if (typeof result === "string") {
      } else if (Array.isArray(result.data)) {
        setVoltageHistoryReport(result.data);
      }
    };
    const getVmsStatusHistoryInit = async () => {
      const result = await getVmsStatusReportHistory(
        `?start-date=${startDate}&end-date=${endDate}&vmses=${vmsId}&limit=100000`
      );
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setStatusHistoryReport(result.data.results);
      }
    };
    getVmsInit();
    getVmsVoltageReportHistoryInit();
    getVmsStatusHistoryInit();
  }, []);
  const handleDelete = async (e: any) => {
    e.preventDefault();

    await deleteVms(vmsId!).then((res) => {
      if (res) {
        toast.warning("Wyświetlacz został usunięty");
        navigate("/vmses");
      }
    });
  };

  const handleDisable = async (e: any) => {
    e.preventDefault();

    await disableVms(vmsId!).then((res) => {
      if (res) {
        toast.success("Wyświetlacz został wyłączony");
        navigate(0);
      } else {
        toast.success("Wyświetlacz został wyłączony");
        navigate(0);
      }
    });
  };

  const handleEnable = async (e: any) => {
    e.preventDefault();

    await enableVms(vmsId!).then((res) => {
      if (res) {
        toast.success("Wyświetlacz został włączony");
        navigate(0);
      } else {
        toast.error(`wyświetlacz ${vmsId} nie odpowiada`);
        navigate(0);
      }
    });
  };
  return (
    <>
      {vms && !apiError ? (
        <>
          <div className="block mb-4 mx-auto border-b border-slate-300 pb-2 max-w-[360px]"></div>
          <div className="mb-5 flex items-center justify-between"></div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <CardDataStats title="Adres instalacji TIP" value={vms?.address} />
            <Link to={`/parkings/${vms.parking.id}`}>
              <CardDataStats
                title="Powiązany Parking z TIP"
                value={vms.parking.name}
              />
            </Link>
            <CardDataStats
              title="Napięcie"
              value={vms.voltage ? `${vms?.voltage?.toString()}V` : ""}
            />
            <CardDataStats title="Status" value={vms?.status.display_value} />
            <CardDataStats
              title="Wyświetlana wartość przez TIP"
              value={
                vms.status.status === "online"
                  ? vms.displayed_content.toString()
                  : "---"
              }
            />
            <CardDataStats
              title="Wolne miejsca parkingowe"
              value={vms?.parking?.free_places_total_public?.toString()}
            />
            <CardDataStats
              title="Bateria"
              value={
                vms.battery_level || vms.battery_level === 0
                  ? `${vms?.battery_level?.toFixed(2).toString()}%`
                  : ""
              }
            />
            {vms.latitude & vms.longitude ? (
              <Link
                to={`https://www.google.com/maps/place/${vms.latitude?.toString()},${vms.longitude?.toString()}`}
                target="_blank"
              >
                <CardDataStats title="Lokalizacja" value={`Nawiguj do TIP`} />
              </Link>
            ) : (
              <CardDataStats title="Lokalizacja" value={`brak lokalizacji`} />
            )}
          </div>
          <div className="block mb-4 mx-auto border-b border-slate-300 pb-2 max-w-[360px]"></div>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
            <span>
              <Button href={`${vmsId}/edit`}>Edytuj</Button>

              {vms.status.id != 1 ? (
                <Button onDoubleClick={handleDisable}>
                  Wyłącz wyświetlacz
                </Button>
              ) : (
                <Button onDoubleClick={handleEnable}>Włącz wyświetlacz</Button>
              )}
              <Button disabled={!isAdmin()} href={`/vmses/add`}>
                Dodaj nowy
              </Button>
              <Button disabled={!isAdmin()} onDoubleClick={handleDelete}>
                Usuń
              </Button>
            </span>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <VmsStatusHistoryTable vmsId={vms.id?.toString()} />
          </div>
          <div className="mt-2 grid grid-cols-1 gap-4 md:mt-6 md:gap-2 2xl:mt-7.5 2xl:gap-7.5">
            <VmsVoltageHistoryChart report={voltageHistoryReport!} />
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <VmsStatusHistoryChart report={statusHistoryReport!} />
          </div>
          <div className="block mb-4 mx-auto border-b border-slate-300 pb-2 max-w-[360px]"></div>
        </>
      ) : !apiError ? (
        <Spinner />
      ) : (
        <>
          Wyświetlacz <b>{vmsId}</b> nie istnieje
        </>
      )}
    </>
  );
};

export default VmsDetails;
