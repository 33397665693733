import { createBrowserRouter } from "react-router-dom";
import App from "../App";

import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/Login";

import HomePage from "../pages/HomePage";
import ParkingsTable from "../components/Tables/ParkingsTable";
import ParkingDetails from "../components/ObjectDetails/ParkingDetails";
import ApiLogsTable from "../components/Tables/ApiLogsTable";
import UsersTable from "../components/Tables/UsersTable";
import VmsesTable from "../components/Tables/VmsesTable";
import VmsDetails from "../components/ObjectDetails/VmsDetails";
import AddVmsForm from "../components/Forms/AddVmsForm";
import EditVmsForm from "../components/Forms/EditVmsForm";
import AddUserForm from "../components/Forms/AddUserForm";
import EditUserForm from "../components/Forms/EditUserForm";
import ParkingOccupanyReportForm from "../components/Reports/ParkingOccupanyReport";
import ChangePasswordForm from "../components/Forms/ChangePasswordForm";
import NotificationRecipientsTable from "../components/Tables/NotificationRecipientsTable";
import VmsStatusHistoryReportForm from "../components/Reports/VmsStatusHistoryReport";
import BatteryVoltageReport from "../components/Reports/BatteryVoltageReport";

export const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute>
            <HomePage>
              <></>
            </HomePage>
          </ProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <VmsesTable />,
          },
          {
            path: "parkings",
            element: <ParkingsTable />,
          },
          {
            path: "parkings/:parkingId",
            element: <ParkingDetails />,
          },
          {
            path: "api-logs",
            element: <ApiLogsTable />,
          },
          {
            path: "users",
            element: <UsersTable />,
          },
          {
            path: "users/add",
            element: <AddUserForm />,
          },
          {
            path: "users/:userId/edit",
            element: <EditUserForm />,
          },
          {
            path: "vmses",
            element: <VmsesTable />,
          },
          {
            path: "vmses/:vmsId",
            element: <VmsDetails />,
          },
          {
            path: "vmses/:vmsId/edit",
            element: <EditVmsForm />,
          },
          {
            path: "vmses/add",
            element: <AddVmsForm />,
          },
          {
            path: "reports/parking-average-occupancy-report",
            element: <ParkingOccupanyReportForm />,
          },
          {
            path: "reports/battery-voltage-report",
            element: <BatteryVoltageReport />,
          },
          {
            path: "reports/vms-status-report",
            element: <VmsStatusHistoryReportForm />,
          },
          {
            path: "change-password",
            element: <ChangePasswordForm />,
          },
          {
            path: "email-notifications",
            element: <NotificationRecipientsTable />,
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
]);
