import moment from "moment";
import React, { useEffect, useState } from "react";
import { ApiLogsResponse } from "../../models/ApiLogs";
import Spinner from "../Spinner/Spinner";
import { getApiLogs } from "../../services/ParkingService";
import Pagination from "@mui/material/Pagination";
type Props = {};

const ApiLogsTable = (props: Props) => {
  const [apiLogsResponse, setApiLogsResponse] = useState<ApiLogsResponse>();
  const [limit, setLimit] = useState(10);
  const [start, setStart] = useState(1);
  useEffect(() => {
    const getApiLogsInit = async () => {
      const result = await getApiLogs(`?start=${start}&limit=${limit}`);
      if (typeof result === "string") {
      } else {
        setApiLogsResponse(result.data);
      }
    };
    getApiLogsInit();
  }, [limit, start]);

  return (
    <>
      {apiLogsResponse ? (
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                    ID
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    URL
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    Status
                  </th>
                  <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                    Response
                  </th>
                  <th className="py-4 px-4 font-medium text-black dark:text-white">
                    Timestamp
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiLogsResponse.results.map((apiLog, key) => (
                  <tr key={key}>
                    <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {apiLog.id}
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">
                        {apiLog.api_url}
                      </p>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <span className="text-black dark:text-white">
                        {apiLog.response_code}
                      </span>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <span className="text-black dark:text-white">
                        {apiLog.response_body}
                      </span>
                    </td>
                    <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                      <p className="text-black dark:text-white">
                        {moment(apiLog.time_created).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-center px-4 py-3">
              <div className="text-sm text-slate-500">
                Wyświetlam{" "}
                <b>
                  {start} - {start - 1 + apiLogsResponse.results.length}
                </b>{" "}
                z {apiLogsResponse.count}
              </div>
              <div className="flex space-x-1">
                <Pagination
                  count={apiLogsResponse.page_count}
                  onChange={(e, value) => setStart((value - 1) * limit + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default ApiLogsTable;
