import React, { useEffect, useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import { Parking } from "../../../models/Parking";
import { getParkingList } from "../../../services/ParkingService";
import Spinner from "../../Spinner/Spinner";

type Props = { selectedParkingId?: string };

const SelectGroupParkings = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const [parkings, setParkings] = useState<Parking[]>();
  useEffect(() => {
    const getParkingsInit = async () => {
      const result = await getParkingList();
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setParkings(result.data.results);
        setSelectedOption(
          props.selectedParkingId ? props.selectedParkingId : ""
        );
      }
    };
    getParkingsInit();
  }, []);

  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  return (
    <>
      {parkings ? (
        <div className="mb-4.5">
          <label className="mb-2.5 block text-black dark:text-white">
            {" "}
            Parking{" "}
          </label>

          <div className="relative z-20 bg-transparent dark:bg-form-input">
            <select
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                changeTextColor();
              }}
              className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                isOptionSelected ? "text-black dark:text-white" : ""
              }`}
            >
              <option
                value=""
                disabled
                className="text-body dark:text-bodydark"
              >
                Wybierz parking
              </option>
              {parkings.map((parking, key) => (
                <option
                  key={parking.id}
                  value={parking.id}
                  className="text-body dark:text-bodydark"
                >
                  {parking.name} ({parking.vmses.length})
                </option>
              ))}
            </select>

            <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
              <SlArrowDown />
            </span>
          </div>
        </div>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </>
  );
};

export default SelectGroupParkings;
