import React, { useState, ReactNode } from "react";
import Header from "../components/Header/index";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { createTheme, ThemeProvider } from "@mui/material";
import useLocalStorage from "../hooks/useLocalStorage";

const themeLight = createTheme({
  palette: {
    mode: "light",
    // primary: {
    //   main: "#3C50E0",
    //   contrastText: "#fff", //button text white instead of black
    // },
  },
});

const themeDark = createTheme({
  palette: {
    mode: "dark",
  },
});

const HomePage: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [light, setLight] = useState(
    useLocalStorage("color-theme", "light")[0] === "light" ? true : false
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleThemeChange = (mode: any) => {
    setLight(mode);
  };

  return (
    <ThemeProvider theme={light ? themeLight : themeDark}>
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <Header
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              headerTitle={""}
              handleThemeChange={handleThemeChange}
            />
            <main>
              <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
