import React, { useEffect, useState } from "react";
import {
  getVmses,
  getVmsVoltageReportHistory,
} from "../../services/ParkingService";
import DatePickerRange from "../Forms/DatePicker/DatePickerRange";
import moment from "moment";
import Spinner from "../Spinner/Spinner";
import { Vms, VmsVoltageHistoryReport } from "../../models/Vms";
import VmsVoltageHistoryChart from "../Charts/VmsVoltageHistoryChart";
import { Link } from "react-router-dom";

type Props = {};

const BatteryVoltageReport = (props: Props) => {
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const [disableHelper, setDisableHelper] = useState<boolean>(false);
  const [granularity, setGranularity] = useState<number>(30);
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(10, "d").format("DD-MM-YYYY").toString()
  );
  const [endDate, setEndDate] = useState<string>(
    moment().format("DD-MM-YYYY").toString()
  );
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<Vms[]>([]);
  const [report, setReport] = useState<VmsVoltageHistoryReport[]>();
  const [vmses, setVmses] = useState<Vms[]>();
  const handleDateRangeChange = (values: any[]) => {
    setStartDate(moment(values[0]).format("DD-MM-YYYY").toString());
    setEndDate(moment(values[1]).format("DD-MM-YYYY").toString());
  };

  useEffect(() => {
    const filteredResults = vmses?.filter((vms) =>
      vms.address
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          search
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    );
    setSearchResults(filteredResults ? filteredResults : []);
  }, [vmses, search]);

  useEffect(() => {
    const getVmsesInit = async () => {
      const result = await getVmses("");
      if (typeof result === "string") {
      } else if (Array.isArray(result.data.results)) {
        setVmses(result.data.results);
      }
    };
    getVmsesInit();
  }, []);

  const getReport = async () => {
    setIsReportLoaded(false);
    const result = await getVmsVoltageReportHistory(
      `?granularity=${granularity}&start-date=${startDate}&end-date=${endDate}`
    );
    if (typeof result === "string") {
    } else if (Array.isArray(result.data)) {
      setReport(result.data);
      setIsReportLoaded(true);
    }
  };

  return (
    <>
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Historia napięcia zasilania znaków
          </h3>
        </div>
        <div className="flex flex-col gap-5.5 p-6.5">
          <div>
            <label className="mb-3 block text-sm font-medium text-black dark:text-white">
              Szczegółowość (granulacja - w minutach)
            </label>
            <div className="relative">
              <input
                className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                type="number"
                placeholder="granularity"
                defaultValue={granularity}
                onChange={(e) => {
                  e.preventDefault();
                  setGranularity(Number(e.target.value));
                }}
              />
              <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center"></div>
            </div>
          </div>
          <DatePickerRange
            handleDateRangeChange={handleDateRangeChange}
            title={"Zakres dat (domyślnie: ostatnie 5 dni)"}
          />

          <button
            className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
            type="submit"
            onClick={(e) => {
              getReport();
              setDisableHelper(true);
              e.preventDefault();
            }}
          >
            Odśwież dane
          </button>
        </div>
      </div>
      {isReportLoaded ? (
        <>
          <div className="w-full flex justify-between items-center mb-3 mt-1 pl-50 pr-50">
            <input
              className="text-black dark:text-white bg-white dark:bg-boxdark dark:border-strokedark w-full pr-11 h-10 pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
              placeholder="Filtruj TIP"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>

          <div className="mt-4 grid grid-cols-1 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <div className="text-sm text-slate-500">
              Wyświetlane TIP: <b>{searchResults.length}</b> z dostępnych{" "}
              {vmses?.length}
            </div>
            <div className="text-sm text-slate-500">
              Zakres dat: <b>{startDate}</b> - <b>{endDate}</b>
            </div>

            {searchResults
              ?.sort((a, b) => a.id - b.id)
              .map((vms, key) => (
                <div key={key}>
                  <p className="relative center">
                    {vms.latitude & vms.longitude ? (
                      <button
                        className="justify-center rounded bg-primary p-2 font-medium text-gray hover:bg-opacity-90 "
                        type="submit"
                        onClick={(e) => {
                          window.open(
                            `https://www.google.com/maps/place/${vms.latitude?.toString()},${vms.longitude?.toString()}`,
                            "_blank",
                            "noopener, noreferrer"
                          );
                        }}
                      >
                        Nawiguj do TIP
                      </button>
                    ) : (
                      ""
                    )}
                    {"   "}
                    Numer TIP:{" "}
                    <Link to={`/vmses/${vms.id.toString()}`}>
                      <b>{vms.id}</b>
                    </Link>
                    , Nazwa TIP:{" "}
                    <Link to={`/vmses/${vms.id.toString()}`}>
                      <b>{vms.address}</b>
                    </Link>
                    , Adres IP:{" "}
                    <Link to={`/vmses/${vms.id.toString()}`}>
                      <b>{vms.ip_address}</b>
                    </Link>
                    , Ostatnia wartość napięcia: <b>{vms.voltage}</b>, Status:{" "}
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                        vms.status.status === "online"
                          ? "bg-success text-success"
                          : vms.status.status === "offline"
                          ? "bg-danger text-danger"
                          : vms.status.status === "new"
                          ? "bg-info text-info"
                          : "bg-warning text-warning"
                      }`}
                    >
                      {vms.status.display_value}
                    </p>
                  </p>
                  <>
                    {" "}
                    <VmsVoltageHistoryChart
                      report={report!.filter((v) => {
                        return v.vms_id == vms.id;
                      })}
                    />
                  </>
                </div>
              ))}
          </div>
        </>
      ) : disableHelper ? (
        <>
          <div className="text-sm text-slate-500">generuję raport</div>
          <div className="text-sm text-slate-500">
            Zakres dat: <b>{startDate}</b> - <b>{endDate}</b>
          </div>
          <div className="text-sm text-slate-500">
            Granulacja: <b>{granularity} minut.</b>
          </div>
          <Spinner />
        </>
      ) : (
        <p>Wygeneruj raport żeby zobaczyć podgląd</p>
      )}
    </>
  );
};
export default BatteryVoltageReport;
