import { ApexOptions } from "apexcharts";

const apexOptions: ApexOptions = {
  tooltip: {
    x: {
        show: true,
        format: 'dd MMM HH:mm',
        formatter: undefined,
    }
},
  legend: {
    show: false,
    position: "top",
    horizontalAlign: "left",
  },
  colors: ["#3C50E0", "#80CAEE"],
  chart: {
    locales: [
      {
        name: "pl",
        options: {
          months: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ],
          shortMonths: [
            "Sty",
            "Lut",
            "Mar",
            "Kwi",
            "Maj",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Paź",
            "Lis",
            "Gru",
          ],
          days: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ],
          shortDays: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"],
          toolbar: {
            exportToSVG: "Pobierz SVG",
            exportToPNG: "Pobierz PNG",
            exportToCSV: "Pobierz CSV",
            selection: "Wybieranie",
            selectionZoom: "Zoom: Wybieranie",
            zoomIn: "Zoom: Przybliż",
            zoomOut: "Zoom: Oddal",
            pan: "Przesuwanie",
            reset: "Resetuj",
          },
        },
      },
    ],
    fontFamily: "Satoshi, sans-serif",
    height: 335,
    dropShadow: {
      enabled: true,
      color: "#623CEA14",
      top: 10,
      blur: 4,
      left: 0,
      opacity: 0.1,
    },

    toolbar: {
      show: true,
    },
    defaultLocale: "pl",
  },

  responsive: [
    {
      breakpoint: 1024,
      options: {
        chart: {
          height: 300,
        },
      },
    },
    {
      breakpoint: 1366,
      options: {
        chart: {
          height: 350,
        },  
      },
    },
  ],
  stroke: {
    width: [1, 1],
    curve: "smooth",
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
      
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "MMMM 'yy",
        day: "ddd dd MMM",
        hour: "HH:mm",
      },
      datetimeUTC: false
    },
  },
  yaxis: {
    title: {
      style: {
        fontSize: "0px",
      },
    },
    labels: {
            "formatter": function (val) {
                return `${val.toFixed(0).toString()}%`
            }
        }
  },
};

export default apexOptions;